import React from 'react'
import axios from 'axios'
import { useQuery } from 'react-query'

//TODO: .env
const EMBEDLY_API_KEY = '1002736901964502af4dba8505057cf1'

/**
 * @returns { Promise<import('./embedly-types').EmbedlyResponse> }
 */
async function getEmbed(_, { url, key }) {
  const response = await axios.get('https://api.embedly.com/1/oembed', {
    params: { url, key },
  })
  return response.data
}

export function Embedly({ url, linkLabel = null }) {
  const { data } = useQuery(
    ['embedly', { url, key: EMBEDLY_API_KEY }],
    getEmbed
  )
  if (!data) {
    return null
  }

  if (data.html) {
    return <div dangerouslySetInnerHTML={{ __html: data.html }}></div>
  } else if (data.url) {
    if (data.thumbnail_url) {
      return (
        <a href={data.url} target="_blank" class="embedly__thumbnail">
          <img src={data.thumbnail_url} alt={data.title} title={data.title} />
        </a>
      )
    }
    return (
      <a
        href={data.url}
        title={data.title}
        target="_blank"
        className="text-left embedly__text"
      >
        {linkLabel ? linkLabel : data.title}
      </a>
    )
  }
}
